import { ArticleList, Article} from './zod-types';
import axios, {AxiosRequestConfig} from "axios";


const GetArticles = async (query?: string): Promise<ArticleList> => {
    const config: AxiosRequestConfig = {
        method: "GET",
        url: `https://www.err403.com/api/articles${query ? query : null}`,
    }
    const response = await axios.request<ArticleList>(config)
    return response.data
}

export {GetArticles}


// const GetArticle = async (slug?: string): Promise<ArticleList> => {
//     console.debug("GetArtical")
//     console.debug(slug)

//     const config: AxiosRequestConfig = {
//         method: "GET",
//         url: `https://www.err403.com/api/articles/${slug ? `?slug=${slug}` : null}`,
//     }

//     const response = await axios.request<ArticleList>(config)
//     return response.data
// }

// export {GetArticle}