import * as React from "react"
import {Link} from 'react-router-dom'
import {cn} from "@/lib/utils"
// import {Icons} from "@/components/ui/icons"
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle
} from "@/components/ui/navigation-menu"

// import err403 from "@/components/ui/logo"
import Logo from "@/img/logo.svg"
import LogoIcon from "@/img/logo-icon.svg"
import { useQuery } from "@tanstack/react-query"
import { GetArticles } from "@/api/queries"
import { queries } from "@testing-library/react"

type Props = {}

const NavigationTop: React.FC <Props> = () : JSX.Element => {

    const blog = useQuery({
        queryKey: ["articles", "latest-10"],
        queryFn: () => GetArticles("?sort=updatedAt:DESC"),
        })


    const isHome = window.location.pathname === "/" ? true : false

    return (
        <nav className="h-16 md:block scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100">

            
            <div className={`flex h-16 items-center px-4 font-semibold ${isHome ? "bg-transparent":"bg-black/70"}`}>

                {/* Logo */}
                <div className="pl-1 hidden md:flex">
                    <a href="/">
                        <img className="h-10" src={Logo} alt="Logo" />
                    </a>
                </div>

                <div className="flex md:hidden">
                    <a href="/">
                        <img className="h-10" src={LogoIcon} alt="Logo" />
                    </a>
                </div>


                {/* Menu  */}
                <div className="ml-auto flex items-center space-x-6 ">
                    <NavigationMenu className="bg-transparent text-white">
                        <NavigationMenuList>
                            <NavigationMenuItem className="border-none">
                                <NavigationMenuTrigger className="text-white">Getting started</NavigationMenuTrigger>

                                <NavigationMenuContent className="bg-black border-none">

                                    <ul className="grid gap-3 p-6 md:w-[600px] lg:w-[900px] lg:grid-cols-[.75fr_1fr]">

                                        <li className="row-span-3">

                                            <NavigationMenuLink asChild>
                                                <a className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md" href="/">
                                                    <img src={LogoIcon} alt="LogoIcon" className="h-32 w-32"/>
                                                    <div className="mb-2 mt-4 text-lg font-medium">
                                                        err403
                                                    </div>
                                                    <p className="text-sm leading-tight text-muted-foreground">
                                                        My personal blog and showcase, built with React, TypeScript, and Tailwind CSS.
                                                    </p>
                                                </a>
                                            </NavigationMenuLink>
                                        </li>

                                        <ListItem key={'blog'} className={'hover:bg-slate-700'} href={''} title={'About Me'}>
                                                {'Notes to self about different topics ranging from 3cx to web development'} 
                                        </ListItem>
                                        <ListItem key={'blog'} className={'hover:bg-slate-700'} href={''} title={'Blog'}>
                                                {'Notes to self about different topics ranging from 3cx to web development'} 
                                        </ListItem>
                                        <ListItem key={'blog'} className={'hover:bg-slate-700'} href={''} title={'Code Snippets'}>
                                                {'Notes to self about different topics ranging from 3cx to web development'} 
                                        </ListItem>

                                    </ul>
                                </NavigationMenuContent>
                            </NavigationMenuItem>

                            <NavigationMenuItem>
                                <NavigationMenuTrigger>Blogs</NavigationMenuTrigger>
                                <NavigationMenuContent className="bg-black">
                                    <ul className="grid w-screen  p-2 md:w-[400px] lg:w-[500px] ">

                                        {blog.isSuccess && blog.data.data.map((blog, index) => (
                                            <ListItem key={`blog-${index}`}
                                                className={'hover:bg-slate-700'}
                                                title={blog.attributes.title}
                                                href={`/blog/${blog.attributes.slug}`}>
                                                <span className="text-sm italic font-light indent-1">{blog.attributes.description}</span>
                                            </ListItem>

                                        ))

                                    } </ul>
                                </NavigationMenuContent>
                            </NavigationMenuItem>

{/* 
                            <NavigationMenuItem>
                                <Link to="/docs" >
                                    <NavigationMenuLink className={
                                        navigationMenuTriggerStyle()
                                    }>
                                        Documentation
                                    </NavigationMenuLink>
                                </Link>
                            </NavigationMenuItem> */}

                        </NavigationMenuList>
                    </NavigationMenu>
                </div>

                {/* Right Side  */}
                <div className="ml-auto flex items-center space-x-4">

                </div>

            </div>
        </nav>
    )
}

const ListItem = React.forwardRef <React.ElementRef<"a">,
    React.ComponentPropsWithoutRef < "a" > > (({className, title, children, ...props}, ref) => {
        return (
            <li>
                <NavigationMenuLink asChild>
                    <a ref={ref}
                        className={
                            cn("block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-grey-600 hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground", className)
                        }
                        {...props}>
                        <div className="text-sm font-medium leading-none">
                            {title}</div>
                        <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                            {children} </p>
                    </a>
                </NavigationMenuLink>
            </li>
        )
    })

ListItem.displayName = "ListItem"


export { NavigationTop }
