import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import reportWebVitals from './reportWebVitals';
import rg4js from 'raygun4js';

rg4js('apiKey', 'YcXOi4YaSsoNAekPsIowbQ');
rg4js('enableCrashReporting', true);
rg4js('enablePulse', true);

const root = ReactDOM.createRoot(document.getElementById('root')as HTMLElement);

const Client = new QueryClient();


root.render (
    <React.StrictMode>
        <QueryClientProvider client={Client}>
            <App/>
            <ReactQueryDevtools/>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
