import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { nightOwl, atomDark,  } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import CopyToClipboard from 'react-copy-to-clipboard';

import { GetArticles } from '@/api/queries'
import { useQuery } from '@tanstack/react-query'
import Footer from '@/pages/footer';

// https://github.com/react-syntax-highlighter/react-syntax-highlighter
// https://prismjs.com/

type Props = {
    id?: string;
    path?: string
}

const Blog: React.FC < Props > = (path): JSX.Element => {

    const location = useLocation();
    const [pathName, setPathName] = useState<string>();

    useEffect(() => {
        if(location) {
            let path = location.pathname.slice(location.pathname.lastIndexOf("/") + 1 , location.pathname.length) ;
            setPathName(path) ;
        }
    }, [location])


    const blog = useQuery({
        queryKey: ["article", pathName],
        queryFn: () => GetArticles(`?filters[slug][$eq]=${pathName}`),
        })


    return (
        <div className="w-full z-0 bg-black/70">
            <div className="container md:w-4/6 px-5 md:px10 md:py-10 mx-auto">

                {blog.isSuccess && blog.data.data && blog.data.data?[0] && (
                    <>
                        <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">{blog.data.data[0]?.attributes.title}</h1>
                        <div className="mt-2 mb-4">
                            <span className="inline-block w-40 h-1 bg-[#FDAA02] rounded-full"></span>
                            <span className="inline-block w-3 h-1 ml-1 bg-[#FDAA02] rounded-full"></span>
                            <span className="inline-block w-1 h-1 ml-1 bg-[#FDAA02] rounded-full"></span>
                        </div>
                        <div className='md:pt-4 md:px-8'>
                            <ReactMarkdown
                            children={blog.data.data[0]?.attributes.content}
                            className='markdown'
                            components={{
                                code({node, inline, className, children, ...props}) {
                                    const match = /language-(\w+)/.exec(className || '')
                                    return !inline && match ? (
                                        <>
                                        {/* <CopyButton valueToCopy={body} buttonTheme={buttonTheme}/> */}
                                        <SyntaxHighlighter
                                            {...props}
                                            children={String(children).replace(/\n$/, '')}
                                            style={nightOwl }
                                            language={match[1]}
                                            // showLineNumbers
                                            // showInlineLneNumbers
                                            wrapLongLines
                                            PreTag="div"
                                        />
                                        </>
                                    ) : (
                                        <code {...props} className={className}>
                                            {children}
                                        </code>
                                    )
                                    }
                                }}
                            remarkPlugins={[remarkGfm]
                            }/>
                        </div>
                    </>
                ) : null }
            </div>
            <Footer/> 
        </div>
    )
}

export default Blog
