import React from 'react'
import LogoIcon from "@/img/logo-icon.svg"
import {Github, Linkedin} from 'lucide-react'

const Footer: React.FC = () : JSX.Element => { 

    return (
        <footer className=" bg-black">
            <div className="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">
                <div className="flex justify-center">
                    <img className="h-20"
                        src={LogoIcon}
                        alt="Logo"/>
                </div>

                <ul className="mt-12 flex justify-center gap-6 md:gap-8">
                    <li>
                        <a href="https://github.com/garethcheyne" rel="noreferrer" target="_blank" className="text-gray-700 transition hover:text-gray-700/75 dark:text-white dark:hover:text-white/75">
                            <span className="sr-only">GitHub</span>
                            <Github className='text-white'/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/garethcheyne/" rel="noreferrer" target="_blank" className="text-gray-700 transition hover:text-gray-700/75 dark:text-white dark:hover:text-white/75">
                            <span className="sr-only">LinkedIn</span>
                            <Linkedin className='text-white'/>
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
