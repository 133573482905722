import * as React from "react"
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import { ParticlesContainer } from "@/components/ui/tsparticals";
import './App.css';


import {NavigationTop} from "./components/elements/navigation-top"

import { Home, Blog } from "./pages/pages"
import BrowserData from "./pages/browserdata";


function App() {

    return (
        <>
            <ParticlesContainer/>
            <Router>
                <div className="flex flex-col no-scrollbar h-screen">

                    <NavigationTop/>

                    <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
                        <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-br from-[#FDAA02] to-[#E62208] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                            style={
                                {clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}
                            }/>
                        </div>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/blog/">
                                <Route path="*" element={<Blog/>}/>
                            </Route>
                            <Route path="/experments/whoami" element={<BrowserData/>}/>
                        </Routes>


                        {/* <div className="dark flex overflow-hidden h-full">
                            <Window title="Test" key="Example" defaultSize={{width: 800, height: 600}}  content={<Example/>}/>

                        </div> */}

                        <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                            <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#FDAA02] to-[#E62208] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                                style={
                                    {clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}
                                }/>
                        </div>
                </div>
            </Router>
        </>
    )}

export default App;
