import {PureComponent} from "react";
import Particles from "react-tsparticles";
import type {Engine}
from "tsparticles-engine";
import {loadFull} from "tsparticles";

export class ParticlesContainer extends PureComponent < unknown > { // this customizes the component tsParticles installation
    async customInit(engine : Engine): Promise < void > { // this adds the bundle to tsParticles
        await loadFull(engine);
    }

    render() {

        return  <Particles
        id="tsparticles"
        init={this.customInit}

        options={{
            background: {
                color: "#fff",
                opacity: 0,
            },
            fullScreen: {
                enable: true,
                zIndex: -1 // -1 is behind the body, so if you have a background you have to use 0 or a different value
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    resize: true,
                },
                modes: {
                    push: {
                        quantity: 0,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: ["#1e1e1e", "#3F3D3E", "#706D6C", "#FCE600","#C9A801","#E62208","#FDAA02"]
                },
                links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.2,
                    width: 1,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: false,
                    speed: 6,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 800,
                    },
                    value: 80,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 5 },
                },
            },
            detectRetina: true,
        }}
    />
    }
}


