import React from 'react'
import ReactDOM from 'react-dom';
import Footer from '@/pages/footer';

import image02 from "@/img/AdobeStock_579510631.jpeg"
import logoreact from "@/img/small-icons/react.png"
import logojavascript from "@/img/small-icons/javascript.png"
import logotypescript from "@/img/small-icons/typescript.png"
import logocsharp from "@/img/small-icons/csharp.png"
import logopython from "@/img/small-icons/python.png"
import logodynamics from "@/img/small-icons/dynamics.png"
import LogoIcon from "@/img/logo-icon.svg"

import {Github, Linkedin} from 'lucide-react'
import {Carousel} from 'flowbite-react';
import { useQuery } from '@tanstack/react-query';

import { ArticleList } from '@/api/zod-types';
import { GetArticles }  from '@/api/queries';


type Props = {
    id?: string;
}


const Home: React.FC < Props > = () : JSX.Element => { // text-4xl font-bold tracking-tight text-gray-300 sm:text-6xl


    const blogs = useQuery({
        queryKey: ["articles"],
        queryFn: () => GetArticles(),
        })

    return (
        <div className="w-full z-0">

            <section className="relative flex flex-col items-center justify-center md:h-screen isolate px-6 md:py-14 lg:px-8">
                <div className="container align-middle mx-auto max-w-2xl py-32 sm:py-48 lg:py-10">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-300 sm:text-6xl">
                            <span>Innovate, Integrate, Elevate:
                            </span>
                            <span className='bg-gradient-to-r from-[#FDAA02] via-[#FCE600] to-[#FDAA02] bg-clip-text text-4xl font-extrabold text-transparent sm:text-6xl'>
                                Tech Unleashed</span>
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-200">
                            Welcome! I'm Gareth, a tech enthusiast on a mission to innovate, integrate, and elevate. With a passion for coding and a fascination for technology's limitless possibilities, I craft solutions that seamlessly blend into our digital lives. Join me in exploring the synergy of innovation and code, where each line transforms ideas into reality.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a href="/" className="rounded-md bg-[#FDAA02] px-3.5 py-2.5 text-black text-sm font-semibold text-white shadow-sm hover:bg-[#FDAA02]/50 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FDAA02]/20">
                                Get started
                            </a>
                            <a href="/" className="text-sm font-semibold leading-6 text-gray-200">
                                Learn more
                                <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative flex flex-col items-center justify-center md:h-screen isolate px-6 md:py-14 lg:px-8 bg-black/80">
                <div className="container max-w-6xl  py-10 mx-auto">

                    <div className="max-w-6xl md:px-6 py-10 mx-auto lg:flex lg:items-center">
                        <div className="w-full space-y-12 lg:w-1/2 ">
                            <div>
                                <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">My Life in Code...</h1>
                                <div className="mt-2">
                                    <span className="inline-block w-40 h-1 bg-[#FDAA02]  rounded-full"></span>
                                    <span className="inline-block w-3 h-1 ml-1 bg-[#FDAA02]  rounded-full"></span>
                                    <span className="inline-block w-1 h-1 ml-1 bg-[#FDAA02]  rounded-full"></span>
                                </div>
                            </div>

                            <div className="md:flex md:items-start md:-mx-4">
                                <span className="inline-block p-2 text-blue-500 bg-bg-[#FDAA02]  rounded-xl md:mx-4 dark:text-white dark:bg-[#FDAA02] ">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"/>
                                    </svg>
                                </span>

                                <div className="mt-4 md:mx-4 md:mt-0">
                                    <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">Dynamic 365 Customization and Integration:</h1>

                                    <p className="mt-3 text-gray-500 dark:text-gray-300">
                                        <ul>
                                            <li>Developed custom solutions within Microsoft Dynamics 365 platform.</li>
                                            <li>Leveraged C#, JavaScript, and TypeScript for customization.</li>
                                            <li>Implemented data manipulation and analysis routines.</li>
                                            <li>Achieved streamlined processes, enhancing user experience.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className="md:flex md:items-start md:-mx-4">
                                <span className="inline-block p-2 text-blue-500 bg-bg-[#FDAA02] rounded-xl md:mx-4 dark:text-white dark:bg-[#FDAA02] ">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"/>
                                    </svg>
                                </span>

                                <div className="mt-4 md:mx-4 md:mt-0">
                                    <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">React Applications for Data Visualization:</h1>

                                    <p className="mt-3 text-gray-500 dark:text-gray-300">
                                        <ul>
                                            <li>Designed and built interactive React applications.</li>
                                            <li>Utilized JavaScript, TypeScript, and React for front-end development.</li>
                                            <li>Visualized complex data sets for better insights.</li>
                                            <li>Transformed raw data into user-friendly visual representations.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className="md:flex md:items-start md:-mx-4">
                                <span className="inline-block p-2 text-blue-500 bg-[#FDAA02] rounded-xl md:mx-4 dark:text-white dark:bg-bg-[#FDAA02] ">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"/>
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"/>
                                    </svg>
                                </span>

                                <div className="mt-4 md:mx-4 md:mt-0">
                                    <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">Python Automation Scripts:</h1>

                                    <p className="mt-3 text-gray-500 dark:text-gray-300">
                                        <ul>
                                            <li>Created Python scripts to automate repetitive tasks.</li>
                                            <li>Streamlined data processing, reducing manual effort.</li>
                                            <li>Implemented data manipulation and analysis routines.</li>
                                            <li>Enhanced efficiency and accuracy in data workflows.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
                            <img className="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full"
                                src={image02}
                                alt=""/>
                        </div>
                    </div>

                    <div className="my-12 border-gray-200 dark:border-gray-700"></div>

                    <div className="grid grid-cols-6 gap-2 lg:gap-6">
                        <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                            <img className="h-10"
                                src={logodynamics}
                                alt="Logo"/>
                        </div>
                        <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                            <img className="h-10"
                                src={logocsharp}
                                alt="Logo"/>
                        </div>
                        <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                            <img className="h-10"
                                src={logotypescript}
                                alt="Logo"/>
                        </div>
                        <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                            <img className="h-10"
                                src={logojavascript}
                                alt="Logo"/>
                        </div>
                        <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                            <img className="h-10"
                                src={logoreact}
                                alt="Logo"/>
                        </div>
                        <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                            <img className="h-10"
                                src={logopython}
                                alt="Logo"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative flex flex-col items-center justify-center md:h-screen isolate px-6 md:py-14 lg:px-8">

                <div className="container max-w-6xl py-10 mx-auto">

                    <div className="text-right">
                        <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">[section 3234] Lorem ipsum dolor sit amet, consectetur</h1>

                        <div className="mt-2">
                            <span className="inline-block w-40 h-1 bg-[#FDAA02]  rounded-full"></span>
                            <span className="inline-block w-3 h-1 ml-1 bg-[#FDAA02]  rounded-full"></span>
                            <span className="inline-block w-1 h-1 ml-1 bg-[#FDAA02]  rounded-full"></span>
                        </div>
                    </div>
                    <main className="relative z-20 w-full md:flex md:items-center xl:mt-12">

                        <div className="grid grid-cols-1 gap-16 lg:grid-cols-3 py-16">

                        {blogs.isFetching && <div>Loading...</div>}
                        {blogs.isError && <div>Error fetching data</div>}

                        {blogs.data && blogs.data.data.map((blog: any) => (

                            <div className="flex flex-col w-full max-w-lg p-8 text-left shadow-2xl lg:mx-auto rounded-xl bg-gradient-to-r from-[#FDAA02] via-[#FCE600] to-[#FDAA02] ">
                                <h2 className="font-semibold tracking-widest text-red-800 uppercase">
                                {blog.attributes.title}
                                    {/* <span className="font-semibold text-red-800  lg:mb-0">Acme's HR
                                    </span> */}
                                </h2>
                                <p className="mx-auto text-base leading-relaxed text-black">{blog.attributes.description}</p>

                                <img alt="testimonial" className="inline-block object-cover object-center w-20 h-20 mt-8 rounded-full" src={LogoIcon}/>
                            </div>
                        ))}

                        </div>
                    </main>

                </div>
            </section>

            <section className="relative flex flex-col items-center justify-center md:h-screen isolate px-6 md:py-14 lg:px-8 bg-black/80">
                <div className="container max-w-6xl py-10 mx-auto">

                    <div className="text-left">
                        <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">[section 45363] Lorem ipsum dolor sit amet, consectetur</h1>

                        <div className="mt-2">
                            <span className="inline-block w-40 h-1 bg-[#FDAA02]  rounded-full"></span>
                            <span className="inline-block w-3 h-1 ml-1 bg-[#FDAA02]  rounded-full"></span>
                            <span className="inline-block w-1 h-1 ml-1 bg-[#FDAA02]  rounded-full"></span>
                        </div>
                    </div>

                    <main className="relative z-20 w-full md:flex md:items-center xl:mt-12">
                        <div className="absolute w-full bg-gradient-to-r from-[#FDAA02] via-[#FCE600] to-[#FDAA02] -z-10 md:h-96 rounded-2xl"></div>

                        <div className="w-full p-1 md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-1 md:justify-evenly">
                            <img className=" h-24 w-24 md:mx-6 object-fit md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[26rem] md:rounded-2xl"
                                src={LogoIcon}
                                alt="working brain"/>

                            <Carousel key="Carousel01"
                                slideInterval={5000}
                                className="p-10 rounded-2xl">

                                <div className="flex h-full p-2">
                                    <div>
                                        <p className="text-xl font-medium tracking-tight text-black">[name]</p>
                                        <p className="text-gray ">[position]</p>
                                    </div>

                                    <p className="mt-4 text-lg leading-relaxed text-blackmd:text-xl">
                                        “Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda”.</p>
                                </div>

                                <div className="flex h-full p-2">
                                    <div>
                                        <p className="text-xl font-medium tracking-tight text-black">[name]</p>
                                        <p className="text-gray ">[position]</p>
                                    </div>

                                    <p className="mt-4 text-lg leading-relaxed text-blackmd:text-xl">
                                        “Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda”.</p>
                                </div>
                                <div className="flex h-full p-2">
                                    <div>
                                        <p className="text-xl font-medium tracking-tight text-black">[name]</p>
                                        <p className="text-gray ">[position]</p>
                                    </div>

                                    <p className="mt-4 text-lg leading-relaxed text-blackmd:text-xl">
                                        “Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda”.</p>
                                </div>
                            </Carousel>
                        </div>
                    </main>
                </div>
            </section>




            <section className="relative flex flex-col items-center justify-center md:h-screen isolate px-6 md:py-14 lg:px-8">

                <div className="container max-w-6xl py-10 mx-auto">

                    <div className="text-right pb-32">
                        <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">[section 3242] Lorem ipsum dolor sit amet, consectetur</h1>

                        <div className="mt-2">
                            <span className="inline-block w-40 h-1 bg-[#FDAA02]  rounded-full"></span>
                            <span className="inline-block w-3 h-1 ml-1 bg-[#FDAA02]  rounded-full"></span>
                            <span className="inline-block w-1 h-1 ml-1 bg-[#FDAA02]  rounded-full"></span>
                        </div>
                    </div>

                    <div className="w-full bg-gradient-to-r from-[#FDAA02] via-[#FCE600] to-[#FDAA02] -z-10 md:h-96 rounded-2xl">

                        <Carousel key="Carousel02"
                            slideInterval={5000} >
                            <div className="p-5">
                                <img src="https://flowbite.com/docs/images/carousel/carousel-2.svg" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
                            </div>

                            <div className="p-5">
                                <img src="https://flowbite.com/docs/images/carousel/carousel-1.svg" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
                            </div>
                            <div className="p-5">
                                <img src="https://flowbite.com/docs/images/carousel/carousel-1.svg" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </section>
        <Footer/>
        </div>
    )
}

export default Home
