import React, { useEffect, useState } from 'react'
import Footer from '@/pages/footer';
import axios from 'axios';
import { object } from 'zod';

type Props = {
    id?: string;
}



const BrowserData: React.FC<Props> = ({id}): JSX.Element => {

    const [userAgent, setUserAgent] = useState<any>();
    const [geolocation, setGeoLocation] = useState<any>();
    const [browserScan, setBrowserScan] = useState<any>();
    




    useEffect(() => {

        axios.get('https://privacy.net/wp-admin/admin-ajax.php?action=browserscan')
        .then(response => {
          setBrowserScan(response.data)
          console.log(response.data);
        })
        .catch(error => {
          // Handle any errors that might occur during the request
          console.error('Error:', error);
        });    
    },[]);


    return (
        <div className="w-full z-0 bg-black/70">
            <div className="container md:w-4/6 px-5 md:px10 md:py-10 mx-auto">
                <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white"> WhoAmI, what your brower is saying about you...</h1>
                    <div className="mt-2 mb-4">
                        <span className="inline-block w-40 h-1 bg-[#FDAA02] rounded-full"></span>
                        <span className="inline-block w-3 h-1 ml-1 bg-[#FDAA02] rounded-full"></span>
                        <span className="inline-block w-1 h-1 ml-1 bg-[#FDAA02] rounded-full"></span>
                    </div>

                <h2>Browser Scan</h2>
                {browserScan && browserScan.map((item: { id: React.Key | null | undefined; propertyName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; propertyValue: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
                    <div key={item.id}> {/* Make sure to use a unique key when rendering lists */}
                        {/* Use 'item' data here */}
                        <p>{item.propertyName}: {item.propertyValue}</p>
                    </div>
                    ))}

            </div>
            <Footer/>
        </div>
    )
}

export default BrowserData